import SVG from "react-inlinesvg";

import arrowRightDarkSVG from "./assets/arrow-right-dark.svg";
import backArrowSVG from "./assets/back-arrow.svg";
import checkmarkSVG from "./assets/checkmark.svg";
import chevronIcon from "./assets/chevron.svg";
import crossNewIconSvg from "./assets/cross-new.svg";
import emailIcon from "./assets/email-icon.svg";
import fitLogoSvg from "./assets/ft-logo.svg";
import lockIconSVG from "./assets/lock.svg";
import profileIconSvg from "./assets/profile.svg";
import searchNewIconSvg from "./assets/search-new.svg";
import settingsSVG from "./assets/settings-icon.svg";
import siftedLogoFtCurrentColor from "./assets/sifted-logo-ft-current-color.svg";
import siftedLogoFtSVG from "./assets/sifted-logo-ft.svg";
import unlockIconSVG from "./assets/unlock.svg";
import externalLink from "./assets/externalLink.svg";
import xLogo from "./assets/x-logo.svg";
import linkedInIcon from "./assets/linkedIn-logo.svg";
import signalIcon from "./assets/signal.svg";
import filterIconSVG from "./assets/filter.svg";
import thumbUpIconSVG from "./assets/thumb-up.svg";
import penSVG from "./assets/pen.svg";
import warningSVG from "./assets/warning.svg";
import eyeOpenSVG from "./assets/eye-open.svg";
import eyeClosedSVG from "./assets/eye-closed.svg";
import locationIconSVG from "./assets/location-icon.svg";
import rocketIconSVG from "./assets/rocket-icon.svg";

const generateIconComponent =
  (svg, title = null, description = null) =>
  (props) => (
    <SVG src={svg} title={title} description={description} {...props} />
  );

export const FtLogo = generateIconComponent(
  fitLogoSvg,
  "Backed by The Financial Times"
);
export const SiftedLogoFt = generateIconComponent(
  siftedLogoFtSVG,
  "Sifted logo"
);
export const SiftedLogoFtCurrentColor = generateIconComponent(
  siftedLogoFtCurrentColor,
  "Sifted logo"
);
export const SignalIcon = generateIconComponent(signalIcon, "Signal logo");
export const LinkedInLogo = generateIconComponent(
  linkedInIcon,
  "LinkedIn logo"
);
export const XLogo = generateIconComponent(xLogo, "Twitter logo");
export const EmailIcon = generateIconComponent(emailIcon, "Email");
export const ExternalLink = generateIconComponent(externalLink);
export const ArrowRightDark = generateIconComponent(arrowRightDarkSVG);
export const SettingsIcon = generateIconComponent(settingsSVG, "Settings icon");
export const CheckmarkIcon = generateIconComponent(checkmarkSVG, "Tick");
export const BackArrowIcon = generateIconComponent(backArrowSVG, "Back arrow");
export const ChevronIcon = generateIconComponent(chevronIcon, "Close");
export const LockIcon = generateIconComponent(lockIconSVG, "Closed lock");
export const UnlockIcon = generateIconComponent(unlockIconSVG, "Open lock");
export const SearchNewIcon = generateIconComponent(searchNewIconSvg, "Search");
export const CrossNewIcon = generateIconComponent(crossNewIconSvg, "Close");
export const ProfileIcon = generateIconComponent(
  profileIconSvg,
  "User Profile"
);

export const FilterIcon = generateIconComponent(filterIconSVG, "Filter Icon");
export const ThumbUpIcon = generateIconComponent(
  thumbUpIconSVG,
  "Thumb Up Icon"
);

export const PenIcon = generateIconComponent(penSVG);
export const WarningIcon = generateIconComponent(warningSVG);
export const EyeOpenIcon = generateIconComponent(eyeOpenSVG);
export const EyeClosedIcon = generateIconComponent(eyeClosedSVG);
export const LocationIcon = generateIconComponent(
  locationIconSVG,
  "Location Icon"
);
export const RocketIcon = generateIconComponent(rocketIconSVG, "Rocket Icon");
